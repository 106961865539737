<template>
	<div class="council">
		<SubHeader title="Error" subtitle="エラー" />

		<div class="container">
			<div class="row justify-content-center">
				<div class="col-lg-12">
					<h2 class="mb-4">File not found</h2>
					<p>ページが見つかりません。URLが間違っているか、古くなっている可能性があります。</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// @ is an alias to /src
import SubHeader from "@/components/common/SubHeader.vue";

export default {
	name: "council",
	components: {
		SubHeader
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h2 {
	font-size: 1.5rem;
	font-weight: bold;
}
.container {
	padding: 5rem 1rem;
	text-align: left;
}
</style>